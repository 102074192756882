@font-face {
  font-family: "pixelNES";
  src: url("./assets/font/Pixel_NES.otf");
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: pixelNES, Arial, Helvetica, sans-serif;
}

body {
  height: 100vh;
  width: 100%;
  overflow-y: hidden;
  background-color: #2D2D2D;
}