.copyright {
    width: 100%;
    font-size: 0.8rem;
    text-align: center;
    margin: auto;
    position: fixed;
    bottom: 0px;
    background-color: #E52521;
    color: #EEE;
    padding: 15px;
    z-index: 100;
}

.copyright-link {
    color: #fb5c00;
}